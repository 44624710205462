// routes
import { useRoutes } from 'react-router';
import routes from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------

export default function App() {
  const isAuth = localStorage.getItem('accessToken');
  const routing = useRoutes(routes(isAuth));
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {routing}
    </ThemeConfig>
  );
}
