/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import * as apiUtils from 'src/utils/apiUtils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';
import PropTypes from 'prop-types';

Upload.propTypes = {
  invoiceId: PropTypes.string,
  setUpdate: PropTypes.func,
  update: PropTypes.number
};

function Upload({ invoiceId, setUpdate, update }) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = async () => {
    const adminId = localStorage.getItem('id');
    try {
      const formData = new FormData();
      formData.append('file', file);
      if (file !== null) {
        if (file.size > 5 * 1024 * 1024) {
          alert('Please upload an evidence image less than 5MB.');
          return false;
        }
        setLoading(true);
        const uploadRes = await apiUtils.uploadEvidenceByInvoiceId(invoiceId, formData);
        const addVerifiedBy = await apiUtils.addVerifiedAdminName(invoiceId, adminId);
        if (uploadRes.status === 200 && addVerifiedBy.status === 200) {
          setUpdate(update === update + 1);
          setOpen(false);
          setLoading(false);
        }
      } else {
        alert('At least add one picture.');
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleUpload = (event) => {
    setFileURL(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Upload
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" padding="2px">
        <DialogTitle id="form-dialog-title">Upload Evidence</DialogTitle>
        <DialogContent>
          <DialogContentText>Please upload an evidence image less than 5MB.</DialogContentText>
          <img src={fileURL} alt="preview" />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden accept="image/*" onChange={handleUpload} />
          </Button>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} color="primary" disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Upload;
