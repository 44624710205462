import { Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import Account from './pages/Account';
import Admin from './pages/Admin';
import Team from './pages/Team';
import Payment from './pages/Payment';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

const routes = (isAuth) => [
  {
    path: '/dashboard',
    element: isAuth ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { element: <Navigate to="/dashboard/home" replace /> },
      { path: 'home', element: <DashboardApp /> },
      { path: 'user', element: <User /> },
      { path: 'register', element: <Register /> },
      { path: 'account', element: <Account /> },
      { path: 'admin', element: <Admin /> },
      { path: 'team', element: <Team /> },
      { path: 'payment', element: <Payment /> }
    ]
  },
  {
    path: '/',
    element: !isAuth ? <LogoOnlyLayout /> : <Navigate to="/dashboard/home" replace />,
    children: [
      { element: <Navigate to="/login" replace /> },
      { path: 'login', element: <Login /> }
      // { path: 'register', element: <Register /> }
    ]
  },
  { path: '*', element: <NotFound /> }
];

export default routes;
