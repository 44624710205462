import { useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import * as apiUtils from 'src/utils/apiUtils';

export const useInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const getInvoices = async (year, trial) => {
    try {
      const getInvoicesRes = await apiUtils.getAllInvoice(year, trial);
      if (getInvoicesRes.status === 200) {
        setInvoices(getInvoicesRes.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { getInvoices, invoices };
};
