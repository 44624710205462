import React from 'react';
import { Card, Stack, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import AccountTabs from '../components/authentication/account/AccountTabs';

const Account = () => (
  <Page title="Account">
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Account
        </Typography>
      </Stack>
      <Card>
        <AccountTabs />
      </Card>
    </Container>
  </Page>
);

export default Account;
