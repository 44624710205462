/* eslint-disable no-unused-vars */
import { filter } from 'lodash';
// import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  // Avatar,
  // Button,
  // Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
// import USERLIST from '../_mocks_/user';

import * as apiUtils from '../utils/apiUtils';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fullName', label: 'FullName', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'phone', label: 'Phone Number', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'postcode', label: 'Postal Code', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'verifiedEmail', label: 'Email Status', alignRight: false },
  { id: 'golfLinkNumber', label: 'Golf Link Number', alignRight: false },
  { id: 'handicap', label: 'Handicap', alignRight: false },
  { id: 'verified', label: 'Verified Status', alignRight: false },
  { id: 'createdTime', label: 'Created Time', alignRight: false },
  { id: 'verifiedBy', label: 'Verified By', alignRight: false },
  { id: 'others', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [USERLIST, setUser] = useState([]);

  const getAllUserInfo = async () => {
    try {
      const getUserRes = await apiUtils.getAllUser();
      if (getUserRes.status === 200) {
        setUser(getUserRes.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUserInfo();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = USERLIST.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Page title="User">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              User Management
            </Typography>
            {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
          </Stack>

          <Card>
            <UserListToolbar
              placeholder="Search user..."
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          fullName,
                          gender,
                          state,
                          postcode,
                          phone,
                          email,
                          golfLinkNumber,
                          handicap,
                          verified,
                          verifiedEmail,
                          verifiedBy,
                          createdTime
                          // avatarUrl,
                        } = row;
                        const isItemSelected = selected.indexOf(fullName) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {fullName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{gender}</TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{state}</TableCell>
                            <TableCell align="left">{postcode}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">
                              {verifiedEmail ? (
                                <Label variant="ghost" color="success">
                                  {sentenceCase('active')}
                                </Label>
                              ) : (
                                <Label variant="ghost" color="warning">
                                  {sentenceCase('pending')}
                                </Label>
                              )}
                            </TableCell>
                            <TableCell align="left">{golfLinkNumber}</TableCell>
                            <TableCell align="left">{handicap}</TableCell>
                            <TableCell align="left">
                              {verified ? (
                                <Label variant="ghost" color="success">
                                  {sentenceCase('active')}
                                </Label>
                              ) : (
                                <Label variant="ghost" color="error">
                                  {sentenceCase('inactive')}
                                </Label>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {new Date(createdTime).toLocaleString()}
                            </TableCell>
                            <TableCell align="left">{verifiedBy}</TableCell>
                            <>
                              {verified || (
                                <TableCell align="right">
                                  <UserMoreMenu row={row} getalluserinfo={getAllUserInfo} />
                                </TableCell>
                              )}
                            </>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
