import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  // Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { Box, IconButton, InputAdornment, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as apiUtils from '../../../../../utils/apiUtils';
import { WarningMsg } from '../../../warningMsg';

const ChangePsw = () => {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const changePswSchema = Yup.object().shape({
    oldPassword: Yup.string().required('OldPassword is required'),
    newPassword: Yup.string()
      .matches(
        /^(?=\S*[a-zA-Z])(?=\S*[0-9#!"$%&'()*+,-./:;<=>?@[\]^_`{|}~])\S{8,}$/,
        'Password is invalid! At least 8 characters with 1 alphabet and 1 non-letter character'
      )
      .max(128, 'Your password is too long!')
      .required('Password required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
      .required('Please confirm your password')
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: ''
    },
    validationSchema: changePswSchema,
    onSubmit: async (values) => {
      const { oldPassword, newPassword } = values;
      const adminId = localStorage.getItem('id');
      try {
        const changePswRes = await apiUtils.changePsw(adminId, oldPassword, newPassword);
        if (changePswRes.status === 200) {
          localStorage.removeItem('id');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('email');
          localStorage.removeItem('firstName');
          localStorage.removeItem('lastName');
          navigate('/login', { replace: true });
        }
      } catch (error) {
        if (error.response.status === 401) {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <>
      {error && <WarningMsg content="Your old password is wrong" />}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showOldPassword ? 'text' : 'password'}
                label="Old Password"
                name="oldPassword"
                variant="outlined"
                {...getFieldProps('oldPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowOldPassword} edge="end">
                        <Icon icon={showOldPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                autoComplete="new-password"
                type={showNewPassword ? 'text' : 'password'}
                label="New Password"
                name="newPassword"
                variant="outlined"
                {...getFieldProps('newPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassword} edge="end">
                        <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                autoComplete="new-password"
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                name="confirmPassword"
                variant="outlined"
                {...getFieldProps('confirmPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowConfirmPassword} edge="end">
                        <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save Change
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ChangePsw;
