/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  warning: {
    margin: '20px 0',
    padding: '3%',
    borderRadius: '10px',
    border: 'solid 2px #eb5757',
    backgroundColor: '#fcebea',
    display: 'flex',
    alignItems: 'center'
  },
  warning_message: {
    fontWeight: 500,
    marginLeft: '16px',
    textAlign: 'left',
    color: '#eb5757'
  }
}));

const WarningMsg = ({ content }) => {
  const classes = useStyles();
  return (
    <div className={classes.warning}>
      <BlockIcon />
      <Typography className={classes.warning_message}>{content}</Typography>
    </div>
  );
};

export default WarningMsg;
