import axios from 'axios';

const baseUrl = process.env.REACT_APP_WACT4_API;
// const baseUrl = 'https://api.uat.wact4bbb.com/api/v1/admin';

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('*');
    }
    return Promise.reject(error);
  }
);

export const verifyEmailExists = (email) =>
  axios({
    method: 'get',
    url: `${baseUrl}/signup`,
    params: {
      email
    }
  });

export const signup = (firstName, lastName, areaCode, phoneNumber, email, password) =>
  axios({
    method: 'post',
    url: `${baseUrl}/signup`,
    data: {
      firstName,
      lastName,
      areaCode,
      phoneNumber,
      email,
      password
    }
  });

export const login = (email, password) =>
  axios({
    method: 'post',
    url: `${baseUrl}/login`,
    data: {
      email,
      password
    }
  });

export const verifyLinkNumber = (userId, verifiedBy) =>
  axios({
    method: 'put',
    url: `${baseUrl}/verification`,
    data: {
      userId,
      verifiedBy
    }
  });

export const getAllUser = () =>
  axios({
    method: 'get',
    url: `${baseUrl}/users`
  });

export const getAllInvoice = (year, trial) =>
  axios({
    method: 'get',
    url: `${baseUrl}/invoices`,
    params: {
      year,
      trial
    }
  });

export const addVerifiedAdminName = (invoiceId, verifiedBy) =>
  axios({
    method: 'put',
    url: `${baseUrl}/paymentVerification`,
    data: {
      invoiceId,
      verifiedBy
    }
  });

export const uploadEvidenceByInvoiceId = (invoiceId, file) =>
  axios({
    method: 'put',
    url: `${baseUrl}/invoices/${invoiceId}`,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const getEvidenceByInvoiceId = (invoiceId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/invoices/${invoiceId}`
  });

export const changePsw = (adminId, oldPassword, newPassword) =>
  axios({
    method: 'post',
    url: `${baseUrl}/${adminId}/resetPassword`,
    data: {
      oldPassword,
      newPassword
    }
  });

export const getAccount = (adminId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/${adminId}/account`
  });

export const getAllAdmin = () =>
  axios({
    method: 'get',
    url: `${baseUrl}/passwordStatus`
  });

export const getAllTeam = (year, trial) =>
  axios({
    method: 'get',
    url: `${baseUrl}/teams/trial`,
    params: {
      year,
      trialNum: trial
    }
  });
