import React, { useState, useEffect } from 'react';
import { Container, Grid, TextField, CircularProgress, Box } from '@mui/material';
import * as apiUtils from '../../../../../utils/apiUtils';

const General = () => {
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);

  const getAccountInfo = async () => {
    const adminId = localStorage.getItem('id');
    try {
      const getAccountRes = await apiUtils.getAccount(adminId);
      if (getAccountRes.status === 200) {
        setValues(getAccountRes.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        console.log('no found');
      }
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth="false">
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First Name(Read only)"
                name="fistName"
                defaultValue={values.firstName}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last Name(Read only)"
                name="lastName"
                defaultValue={values.lastName}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Email(Read only)"
                name="email"
                defaultValue={values.email}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Phone Number(Read only)"
                name="phoneNumber"
                defaultValue={values.phoneNumber}
                variant="outlined"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default General;
