// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppNewUsers,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits
} from '../components/_dashboard/home';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4">Hi, Welcome back(Fake Data)</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <AppWeeklySales />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <AppNewUsers />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <AppWebsiteVisits />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <AppCurrentVisits />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <AppOrderTimeline />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
