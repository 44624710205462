import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import * as apiUtils from 'src/utils/apiUtils';

export const useTeam = () => {
  const [team, setTeam] = useState([]);
  const getTeam = async (year, trial) => {
    try {
      const getTeamRes = await apiUtils.getAllTeam(year, trial);
      if (getTeamRes.status === 200) {
        setTeam(getTeamRes.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { getTeam, team };
};
