/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Autocomplete,
  TextField
} from '@mui/material';

// components
// eslint-disable-next-line import/no-unresolved
import { useInvoices } from 'src/components/payment/hooks';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import Preview from '../components/payment/preview/Preview';
import Upload from '../components/payment/upload/Upload';

const years = ['2021', '2022', '2023'];
const trials = ['1', '2', '3'];
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fullName', label: 'FullName', alignRight: false },
  { id: 'memberType', label: 'Position', alignRight: false },
  { id: 'invoiceNumber', label: 'Invoice Number', alignRight: false },
  { id: 'paymentAmount', label: 'Payment Amount', alignRight: false },
  { id: 'isPaid', label: 'Payment status', alignRight: false },
  { id: 'evidence', label: 'Uploaded evidence', alignRight: false },
  { id: 'verifiedBy', label: 'Verified by', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.invoiceNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [update, setUpdate] = useState(0);
  const [year, setYear] = useState('2022');
  const [trial, setTrial] = useState('');
  const { getInvoices, invoices } = useInvoices();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;

  const filteredUsers = applySortFilter(invoices, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const initialValues = {
    year: '2022',
    trial: ''
  };
  // const validationSchema = Yup.object().shape({
  //   year: Yup.string().trim().required('Required!')
  // });

  const handleOnSubmit = (values) => {
    if (values.year === '') {
      return;
    }
    setYear(values.year);
    setTrial(values.trial);
  };

  useEffect(() => {
    getInvoices(year, trial);
  }, [update, year, trial]);

  return (
    <>
      <Page title="Payment">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Payment Management
            </Typography>
          </Stack>
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            // validationSchema={validationSchema}
          >
            {({ setFieldValue }) => (
              <Form>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb={5}
                  spacing={3}
                >
                  <span>Year: </span>
                  <Field
                    as={Autocomplete}
                    name="year"
                    disablePortal
                    id="custom-input-demo"
                    options={years}
                    defaultValue="2022"
                    onChange={(e, value) => setFieldValue('year', value || '')}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <span>Captain Qualifying Trial: </span>
                  <Field
                    as={Autocomplete}
                    name="trial"
                    disablePortal
                    id="combo-box-demo"
                    options={trials}
                    onChange={(e, value) => setFieldValue('trial', value || '')}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button type="submit" variant="outlined">
                    Search
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              placeholder="Search by invoice number..."
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={invoices.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          invoiceId,
                          fullName,
                          position,
                          invoiceNumber,
                          price,
                          isPaid,
                          verifiedBy
                        } = row;
                        const isItemSelected = selected.indexOf(fullName) !== -1;
                        return (
                          <TableRow
                            key={invoiceId}
                            tabIndex={-1}
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            align="center"
                          >
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {fullName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {position}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {invoiceNumber}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {price}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                checked={isPaid}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </TableCell>
                            <>
                              {isPaid ? (
                                <TableCell alignItems="center">
                                  <Preview invoiceId={invoiceId} />
                                </TableCell>
                              ) : (
                                <TableCell alignItems="center">
                                  <Upload
                                    invoiceId={invoiceId}
                                    setUpdate={setUpdate}
                                    update={update}
                                  />
                                </TableCell>
                              )}
                            </>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {verifiedBy}
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={invoices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
