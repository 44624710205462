/* eslint-disable react/jsx-curly-brace-presence */
import { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { Formik, Form, Field } from 'formik';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Autocomplete,
  TextField
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { useTeam } from 'src/components/team/hooks';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';

const years = ['2021', '2022', '2023'];
const trials = ['1', '2', '3'];
const TABLE_HEAD = [
  { id: 'teamName', label: 'Team Name', alignRight: false },
  { id: 'nominatedCaptain', label: 'Nominated Captain', alignRight: false },
  { id: 'teamMembers', label: 'Team Members', alignRight: false }
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.teamName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Team = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [year, setYear] = useState('2022');
  const [trial, setTrial] = useState('');
  const { getTeam, team } = useTeam();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - team.length) : 0;

  const filteredUsers = applySortFilter(team, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleOnSubmit = (values) => {
    if (values.year === '') {
      return;
    }
    setYear(values.year);
    setTrial(values.trial);
  };
  const initialValues = {
    year: '2022',
    trial: ''
  };

  useEffect(() => {
    getTeam(year, trial);
  }, [year, trial]);

  return (
    <>
      <Page title="Team">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Team Management
            </Typography>
          </Stack>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
            {({ setFieldValue }) => (
              <Form>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  mb={5}
                  spacing={3}
                >
                  <span>Year: </span>
                  <Field
                    as={Autocomplete}
                    name="year"
                    disablePortal
                    id="custom-input-demo"
                    options={years}
                    defaultValue="2022"
                    onChange={(e, value) => setFieldValue('year', value || '')}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <span>Captain Qualifying Trial: </span>
                  <Field
                    as={Autocomplete}
                    name="trial"
                    disablePortal
                    id="combo-box-demo"
                    options={trials}
                    onChange={(e, value) => setFieldValue('trial', value || '')}
                    sx={{ width: 150 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button type="submit" variant="outlined">
                    Search
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              placeholder="Search by team name..."
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={team.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { teamId, teamName, teamMemberList } = row;
                        const nominatedCaptain = teamMemberList.find(
                          (e) => e.membertype.toString() === 'NominatedCaptain'
                        );
                        const members = teamMemberList.filter(
                          (e) => e.membertype.toString() !== 'NominatedCaptain'
                        );
                        const isItemSelected = selected.indexOf(teamName) !== -1;
                        return (
                          <TableRow
                            key={teamId}
                            tabIndex={-1}
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            align="center"
                          >
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {teamName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {nominatedCaptain.memberFirstName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {members.map((member) => (
                                  <Typography key={member.id} variant="subtitle2" noWrap>
                                    {member.memberFirstName}
                                  </Typography>
                                ))}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={team.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default Team;
