import React, { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import * as apiUtils from 'src/utils/apiUtils';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

Preview.propTypes = {
  invoiceId: PropTypes.string
};

function Preview({ invoiceId }) {
  const [open, setOpen] = useState(false);
  const [evidenceImg, setEvidenceImg] = useState();
  const handleClickOpen = async () => {
    setOpen(true);
    try {
      const imgPreviewRes = await apiUtils.getEvidenceByInvoiceId(invoiceId);
      if (imgPreviewRes.status === 200) {
        console.log(imgPreviewRes.data);
        setEvidenceImg(imgPreviewRes.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Preview
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Evidence</DialogTitle>
        <DialogContent>
          <img src={evidenceImg} alt="evidence" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Preview;
