/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
// import { Link as RouterLink } from 'react-router-dom';
// import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as apiUtils from '../../../utils/apiUtils';

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];

const UserMoreMenu = (props) => {
  const { row, getalluserinfo } = props;
  // console.log(row);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('false'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    const userId = row.id;
    const adminId = localStorage.getItem('id');
    try {
      const verifyLinkNumberRes = await apiUtils.verifyLinkNumber(userId, adminId);
      if (verifyLinkNumberRes.status === 200) {
        setOpen(false);
        getalluserinfo();
        setValues({
          ...values,
          verified: row.verified,
          verifiedBy: row.verifiedBy
        });
      }
    } catch (error) {
      if (error.response.status === 404) {
        console.log('admin no found');
      }
    }
  };

  const [values, setValues] = useState({
    id: row.id,
    name: row.fullName,
    gender: row.gender,
    age: row.age,
    phoneNumber: row.phone,
    state: row.state,
    postcode: row.postcode,
    email: row.email,
    verifiedEmail: row.verifiedEmail,
    golfLinkNumber: row.golfLinkNumber,
    handicap: row.handicap,
    verified: row.verified,
    verifiedBy: row.verifiedBy
  });
  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          // component={RouterLink}
          // to="#"
          sx={{ color: 'text.secondary' }}
          onClick={handleClickOpen}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Review" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <Dialog
          fullScreen={fullScreen}
          // maxWidth="false"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {`Please Review ${values.name}'s golf link number and handicap`}
          </DialogTitle>
          <Divider />
          {/* <DialogContentText> */}
          <form autoComplete="off" noValidate {...props}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Full Name(Read only)"
                      name="name"
                      defaultValue={values.name}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Gender(Read only)"
                      name="gender"
                      defaultValue={values.gender}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email(Read only)"
                      name="email"
                      defaultValue={values.email}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number(Read only)"
                      name="phoneNumber"
                      defaultValue={values.phoneNumber}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Golf Link Number(Read only)"
                      name="golfLinkNumber"
                      defaultValue={values.golfLinkNumber}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Handicap(Read only)"
                      name="handicap"
                      defaultValue={values.handicap}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Select State"
                      name="state"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.state}
                      variant="outlined"
                    >
                      {states.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid> */}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <DialogActions>
                  <Button color="primary" variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" onClick={handleConfirm}>
                    Confirm
                  </Button>
                </DialogActions>
              </Box>
            </Card>
          </form>
        </Dialog>
      </Menu>
    </>
  );
};

export default UserMoreMenu;
