/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';

import * as apiUtils from '../../../utils/apiUtils';
import { WarningMsg } from '../warningMsg';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [phone, setPhone] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const handleClose = () => {
    setSuccess(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const phoneNumber = phone.replace(areaCode, '');
      const { firstName, lastName, email } = values;
      const password = 'password12345';
      try {
        const verifyEmail = await apiUtils.verifyEmailExists(email);
        const signupRes = await apiUtils.signup(
          firstName,
          lastName,
          areaCode,
          phoneNumber,
          email,
          password
        );
        if (verifyEmail.status === 200 && signupRes.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            navigate('/dashboard', { replace: true });
          }, 3000);
        }
      } catch (error) {
        if (error.response.status === 409) {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {error && <WarningMsg content="Email exist" />}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="First name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Last name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <MuiPhoneNumber
              fullWidth
              autoComplete="phoneNumber"
              defaultCountry="au"
              label="Phone Number"
              variant="outlined"
              value={phone}
              onChange={(phone, formattedValue) => {
                const code = `+${formattedValue.dialCode}`;
                const phoneNumber = phone.replace(/\s*/g, '');
                setPhone(phoneNumber);
                setAreaCode(code);
              }}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={success}
              onClose={handleClose}
            >
              <Alert severity="success">Create Account Success!</Alert>
            </Snackbar>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
