import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
import personFill from '@iconify/icons-eva/person-fill';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfigNormal = [
  {
    title: 'dashboard',
    path: '/dashboard/home',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Account',
    path: '/dashboard/account',
    icon: getIcon(personFill)
  }
];

export default sidebarConfigNormal;
